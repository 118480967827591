import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { createGlobalStyle, css } from 'styled-components'
import ContainerMain from './components/ContainerMain'
import ContainerMainDev from './components/ContainerMainDev'
import ErrorBoundary from '../../topics/Error/widgets/ErrorBoundary'
import translate from '../../modules/translate'
import Sitemap from './components/Sitemap'
import Meta from '../../components/Meta'
import ReactGA from 'react-ga'
import { withRouter } from 'react-router'
import Footer from './components/Footer'
import isStolApp from '../../modules/is-stolapp'
import loadable from '@loadable/component'
import AdWallpaper from '../../components/AdWallpaper'
import WebLinks from './components/WebLinks'
import AdSuperBanner from './components/AdSuperBanner'
import AdSky from './components/AdSky'
import ExperiencePiano from '../../vendor/Piano/components/ExperiencePiano'
import AdBlockMessage from '../../components/AdBlockMessage'
import IEMessage from '../../components/IEMessage'
import SafariMessage from '../../components/SafariMessage'
import appBottomPadding, { iPhoneXPadding } from '../../modules/mixins/app-bottom-padding'
import { AdsProvider } from '../../components/ContextAds'
import getParameterByName from '../../bundles/BundleAds/modules/get-parameter-by-name'
import EmailVerificationResult from '../../components/EmailVerificationResult'
import Header from './components/Header'
import CMPProvider from '../../vendor/Sourcepoint/components/CMPProvider'

const NewNewsMessage = loadable(
  () => import(/* webpackChunkName: "NewNewsMessage" , webpackPrefetch: true */ '../../components/NewNewsMessage'),
  { ssr: false },
)

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${(props) => props.theme.color.background};
  }
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 100vw;
  padding-bottom: ${(props) => (props.isStolApp ? '0' : '100px')};
  ${(props) =>
    !props.isStolApp &&
    css`
      @media (max-width: 450px) {
        padding-bottom: 0;
      }
    `}
  ${(props) => props.isStolApp && appBottomPadding};
  ${(props) => props.isIphoneX && iPhoneXPadding};
`
const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  @media (max-width: 1380px) {
    flex-direction: column;
  }
`
let ContainerMainUse = process.env.NODE_ENV !== 'production' ? ContainerMainDev : ContainerMain
const StyledMainContainer = styled(ContainerMainUse)`
  display: flex;
  flex-direction: column;
  margin-left: 300px;
  background-color: #fff;

  @media (max-width: 1680px) {
    margin-left: 160px;
  }

  @media (max-width: 1540px) {
    margin-left: 0;
  }

  @media (max-width: 1380px) {
    margin: 0 auto;
  }

  @media (max-width: 1080px) {
    width: 100%;
    overflow: ${(props) => (props.isStolApp ? 'unset' : 'hidden')};
  }
  @media print {
    margin: 0;
    width: 100%;
    min-height: unset;
    box-sizing: unset;
    box-shadow: unset;
  }
  ${(props) =>
    props.isStolApp &&
    css`
      display: flex;
      box-sizing: unset;
      box-shadow: unset;
    `}
`

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledFooterMainContainer = styled(StyledMainContainer)`
  box-shadow: none;
  display: flex;
  background-color: ${(props) => props.theme.color.background};
  flex-direction: row;
  min-height: unset;
  height: unset;
  padding-bottom: ${(props) => props.theme.sizes.gapVerticalHalf};
  margin-bottom: 100px;
  @media (max-width: 599px) {
    flex-direction: column;
    margin-bottom: 80px;
  }
  @media print {
    display: none;
  }
`

const StyledHeader = styled(Header)`
  width: 1078px;
  max-width: 100%;
  margin-left: 300px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  background-color: white;
  @media (max-width: 1680px) {
    margin-left: 160px;
  }

  @media (max-width: 1540px) {
    margin-left: 0;
  }

  @media (max-width: 1380px) {
    margin: 0 auto;
  }

  @media (max-width: 1080px) {
    width: 100%;
  }
`

const StyledAdSky = styled(AdSky)`
  order: 1;
`

const LayoutDefault = ({
                         children,
                         adIds,
                         meta,
                         showAds = true,
                         waitAds = false,
                         updateAds = false,
                         pianoTargets,
                         superBannerBackground,
                         disable = false,
                         targeting,
                         location,
                         ...props
                       }) => {
  const countPageViews = () => {
    if (!isStolApp()) {
      ReactGA.set({ page: location.pathname })
      ReactGA.pageview(location.pathname, null, meta.title)
    }
  }

  useEffect(() => {
    if (meta) {
      countPageViews()
    }
    if (
      window.sessionStorage.getItem('fireTentacles') !== 'start' &&
      window.sessionStorage.getItem('fireTentacles') !== 'home' &&
      !isStolApp()
    ) {
      window.postMessage('activateTentacles')
    }
    return () => {
      window.sessionStorage.setItem('fireTentacles', 'home')
    }
  }, [meta])

  if (!adIds && meta && meta.general && meta.general.adIds) {
    adIds = meta.general.adIds
  }

  if (disable) {
    return children
  }
  return (
    <AdsProvider meta={meta} showAds={showAds} targeting={targeting} wait={waitAds} update={updateAds}>
      <CMPProvider active={!props.cmpIgnore}>
        {meta && <ExperiencePiano adTargeting={meta && meta.adsTargeting} {...pianoTargets} />}
        {!isStolApp() && <AdWallpaper />}
        <Wrapper isStolApp={isStolApp()} {...props}>
          <GlobalStyle />
          <Meta meta={meta} />
          {!isStolApp() && <NewNewsMessage />}
          {!isStolApp() && <AdBlockMessage />}
          <AdSuperBanner active={meta && showAds} adId={adIds && adIds[0]} />
          {!isStolApp() && <IEMessage />}
          {!isStolApp() && <SafariMessage />}
          {getParameterByName('emailVerificationResult') && window.history.length <= 1 && (
            <EmailVerificationResult success={getParameterByName('emailVerificationResult') === 'success'} />
          )}
          <ContentContainer>
            <StyledAdSky active={meta && showAds} adId={adIds && adIds[1]} />
            <ErrorBoundary
              text={translate('error_default')}
              link='/'
              linkText={translate('error_try_homepage')}
              logError='default layout screen'
            >
              <LayoutContainer>
                {!isStolApp() && (
                  <ErrorBoundary view='silent'>
                    <StyledHeader meta={meta} />
                  </ErrorBoundary>
                )}
                <StyledMainContainer className='mainContainer' isStolApp={isStolApp()} hideOverflow>
                  <AdSuperBanner
                    mobile={true}
                    active={meta && showAds}
                    adId={adIds && adIds[0]}
                    superBannerBackground={superBannerBackground}
                    location={location}
                  />
                  {children}
                  {!isStolApp() && <Sitemap />}
                  {!isStolApp() && <Footer />}
                </StyledMainContainer>
                {!isStolApp() && (
                  <StyledFooterMainContainer>
                    <WebLinks />
                  </StyledFooterMainContainer>
                )}
              </LayoutContainer>{' '}
            </ErrorBoundary>
          </ContentContainer>
        </Wrapper>
      </CMPProvider>
    </AdsProvider>
  )
}

LayoutDefault.propTypes = {
  /**
   * Surrounding layouts will not be shown and additional services will not be included, if true
   */
  disable: PropTypes.bool,
  children: PropTypes.any.isRequired,
  adIds: PropTypes.arrayOf(PropTypes.string),
  targeting: PropTypes.shape({
    STOL7_seite: PropTypes.string.isRequired,
    STOL7_unterseite: PropTypes.string,
    STOL7_rubrik: PropTypes.string,
    STOL7_unterrubrik: PropTypes.string,
    STOL7_bezirk: PropTypes.string,
    STOL7_id: PropTypes.string,
    STOL7_url: PropTypes.string,
    STOL7_lokal: PropTypes.string,
  }),
}

export default withRouter(LayoutDefault)
